import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";

import { RegistrationComponent } from "./registration.component";
import { MainRegistrationComponent } from "./main/main.component";
import { CreateComponent } from "./create/create.component";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import {
  LocationStrategy,
  HashLocationStrategy,
  CommonModule,
} from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

import { CreateService } from "./create/create.service";
import { LoginService } from "../login/login.service";
import { RegistrationService } from "./registration.service";

const routes: Routes = [
  {
    path: "registration",
    component: RegistrationComponent,
    children: [
      { path: "create", component: CreateComponent },
      { path: "free", component: CreateComponent },
      { path: "join/:uuid", component: MainRegistrationComponent },
      { path: "join", component: MainRegistrationComponent },
    ],
  },
];

@NgModule({
    exports: [RegistrationComponent, CreateComponent], imports: [BrowserModule,
        FormsModule,
        CommonModule,
        ReactiveFormsModule,
        TooltipModule.forRoot(),
        RouterModule.forRoot(routes, {}), RegistrationComponent,
        CreateComponent,
        MainRegistrationComponent], providers: [
        CreateService,
        LoginService,
        RegistrationService,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class RegistrationModule {}
